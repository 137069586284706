import { Component, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from './services/auth.service';
import { UtilityService } from './services/utility.service';

// tslint:disable-next-line: ban-types
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  is_pwa: boolean = false;
  pwa_window = {
    height: 800,
    width: 400
  };

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private swPush: SwPush,
    private swUpdate: SwUpdate,
    private authService: AuthService,
    private utilityService: UtilityService
  ) {
    let auth = this.authService.getUser();

    this.is_pwa = this.checkIfIsPwa();

    if(this.swUpdate.available) {
      this.swUpdate.available.subscribe(() => {
        this.utilityService.prompt({
          icon: 'success',
          title: 'A new version is available.',
          text: 'It is required that the system you use is using the latest version. New version will be applied on next page refresh.',
          showCancelButton: false
        }).then((result: any) => {
          this.spinner.show();

          window.location.reload();
        });
      });
    }

    if(this.swPush.messages && auth != null) {
      this.swPush.messages.subscribe();
    }

    this.router.events.subscribe((event: any) => {
      if(event instanceof NavigationEnd) {
        gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects
        });
      }
    });

    this.pwaWindowResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.pwaWindowResize();
  }

  checkIfIsPwa() {
    let navi: any = {
      ...window.navigator
    };

    return window.matchMedia('(display-mode: standalone)').matches
      || navi.standalone === true;
  }

  pwaWindowResize() {
    if(this.is_pwa) {
      window.resizeTo(this.pwa_window.width, this.pwa_window.height);
    }
  }
}
