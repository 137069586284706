import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL, HTTP_OPTIONS } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {
  constructor(
    private http: HttpClient
  ) { }

  getEmployeeAttendanceList(id_number: any) {
    return this.http.get(`${API_URL}/attendance/${id_number}/list`, HTTP_OPTIONS);
  }

  getAllEmployeeAttendance(id_number: any, stamp: any, approved_by_id_number: any = null) {
    return this.http.get(`${API_URL}/attendance/${id_number}/get-all?stamp=${stamp}${approved_by_id_number != null ? '&approvedByIdNumber=' + approved_by_id_number : ''}`, HTTP_OPTIONS);
  }

  getAllEmployeeAttendanceByMonth(id_number: any, stamp: any, approved_by_id_number: any = null) {
    return this.http.get(`${API_URL}/attendance/${id_number}/get-all-by-month?stamp=${stamp}${approved_by_id_number != null ? '&approvedByIdNumber=' + approved_by_id_number : ''}`, HTTP_OPTIONS);
  }

  getEmployeeAttendance(id_number: any, stamp: any, approved_by_id_number: any = null) {
    return this.http.get(`${API_URL}/attendance/${id_number}/get?stamp=${stamp}${approved_by_id_number != null ? '&approvedByIdNumber=' + approved_by_id_number : ''}`, HTTP_OPTIONS);
  }

  getEmployeeShiftList(id_number: any) {
    return this.http.get(`${API_URL}/attendance/${id_number}/shifts/list`, HTTP_OPTIONS);
  }

  getCurrentShift(id_number: any) {
    return this.http.get(`${API_URL}/attendance/${id_number}/get-current-shift`, HTTP_OPTIONS);
  }

  createAttendance(params: any) {
    return this.http.post(`${API_URL}/attendance/create`, params, HTTP_OPTIONS);
  }

  recordShift(params: any) {
    return this.http.post(`${API_URL}/attendance/record-shift`, params, HTTP_OPTIONS);
  }

  updateAttendance(attendance_id: any, params: any) {
    return this.http.put(`${API_URL}/attendance/${attendance_id}/update`, params, HTTP_OPTIONS);
  }

  deleteAttendance(attendance_id: any) {
    return this.http.delete(`${API_URL}/attendance/${attendance_id}/delete`, HTTP_OPTIONS);
  }

  updateAttendanceStatus(attendance_id: number, status: string, id_number: string) {
    return this.http.put(`${API_URL}/attendance/${attendance_id}/status`, {
      Idnumber: id_number,
      Status: status
    }, HTTP_OPTIONS);
  }
}
