import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SpinnerComponent } from '../components/spinner/spinner.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PaginationComponent } from '../components/pagination/pagination.component';
import { AttendanceCalendarComponent } from '../components/attendance-calendar/attendance-calendar.component';
import { BlockedAccessComponent } from '../components/blocked-access/blocked-access.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { LabelBoxComponent } from '../components/label-box/label-box.component';
import { SnowComponent } from '../components/snow/snow.component';
import { UiEffectsComponent } from '../components/ui-effects/ui-effects.component';
import { FallingHeartsComponent } from '../components/falling-hearts/falling-hearts.component';
import { MaintenanceModeComponent } from '../components/maintenance-mode/maintenance-mode.component';
import { TabsModule } from 'ngx-bootstrap/tabs';

@NgModule({
  declarations: [
    BlockedAccessComponent,
    PaginationComponent,
    SpinnerComponent,
    AttendanceCalendarComponent,
    LabelBoxComponent,
    UiEffectsComponent,
    SnowComponent,
    FallingHeartsComponent,
    MaintenanceModeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    PaginationModule,
    TooltipModule,
    NgSelectModule,
    PopoverModule
  ],
  exports: [
    BlockedAccessComponent,
    PaginationComponent,
    SpinnerComponent,
    AttendanceCalendarComponent,
    LabelBoxComponent,
    UiEffectsComponent,
    MaintenanceModeComponent,

    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    CollapseModule,
    PaginationModule,
    TooltipModule,
    NgSelectModule,
    CarouselModule,
    TabsModule
  ]
})
export class SharedModule { }
