import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './pages/status/not-found/not-found.component';
import { UnderMaintenanceComponent } from './pages/status/under-maintenance/under-maintenance.component';
import { TimeClockComponent } from './pages/time-clock/time-clock.component';

const routes: Routes = [
  {
    path: '',
    component: TimeClockComponent
  },
  {
    path: 'under-maintenance',
    component: UnderMaintenanceComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
