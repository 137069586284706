<div class="d-flex flex-column flex-md-row align-items-center min-vh-100 vw-100 design-time-clock">
  <div class="display-space">
    <div class="container">
      <div class="d-inline-block text-center w-100">
        <img src="assets/svgs/logo.svg" class="site-logo">
        <div class="display-text-lg mt-1">
          <strong>Time Clock System</strong>
        </div>
      </div>
    </div>
  </div>
  <div class="workspace">
    <div class="d-flex p-4 min-vh-100 w-100">
      <div class="d-flex align-items-center justify-content-center flex-fill w-100" *ngIf="auth == null">
        <div class="login-block card">
          <div class="card-body">
            <div class="display-title m-0">Clock In</div>
            <p>Keep track of your work hours</p>
            <form [formGroup]="login_form" (submit)="login()">
              <div class="form-group" *ngIf="last_logged_in == null">
                <label for="" class="mb-1">E-mail Address or ID Number:</label>
                <input type="text" class="form-control form-control-lg" formControlName="Username" autofocus>
              </div>
              <ng-container *ngIf="last_logged_in != null">
                <div class="text-center">
                  <div class="icon icon-shape icon-lg bg-gradient-success d-inline-flex d-md-none align-items-center justify-content-center position-relative shadow-danger text-center rounded-circle">
                    <img [src]="utilityService.getApiUrl() + '/Storage/Images/Employees/' + profile_picture" class="profile-img profile-img-xl rounded-circle" *ngIf="profile_picture != null" (error)="profile_picture = null" alt="user-profile">
                    <span class="fa-solid fa-user fa-2x fa-fw text-white" *ngIf="profile_picture == null"></span>
                    <button type="button" class="switch-account" tooltip="Switch Account" (click)="switchAccount($event)">
                      <span class="fa-solid fa-exchange fa-sm fa-fw"></span>
                    </button>
                  </div>
                  <div class="icon icon-shape icon-xl bg-gradient-success d-none d-md-inline-flex align-items-center justify-content-center position-relative shadow-danger text-center rounded-circle">
                    <img [src]="utilityService.getApiUrl() + '/Storage/Images/Employees/' + profile_picture" class="profile-img profile-img-xxl rounded-circle" *ngIf="profile_picture != null" (error)="profile_picture = null">
                    <span class="fa-solid fa-user fa-2x fa-fw text-white" *ngIf="profile_picture == null"></span>
                    <button type="button" class="switch-account" tooltip="Switch Account" (click)="switchAccount($event)">
                      <span class="fa-solid fa-exchange fa-sm fa-fw"></span>
                    </button>
                  </div>
                  <div class="mb-2">
                    <small class="fw-bold text-dark">{{ last_logged_in.first_name }}</small>
                  </div>
                </div>
              </ng-container>
              <div class="form-group">
                <label for="" class="mb-1">Password:</label>
                <input type="password" class="form-control form-control-lg" formControlName="Password">
              </div>
              <div class="text-center">
                <button type="submit" class="btn btn-primary btn-lg">
                  <span class="fa-solid fa-right-to-bracket fa-fw"></span>
                  <span class="ms-2">Clock In</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column flex-fill w-100" *ngIf="auth != null">
        <div class="card shadow-lg mb-4">
          <div class="card-body">
            <div class="d-flex align-items-center flex-column flex-md-row">
              <div class="icon icon-shape icon-xl bg-gradient-success border border-2 border-white d-inline-flex align-items-center justify-content-center shadow-danger text-center overflow-hidden">
                <img [src]="utilityService.getApiUrl() + '/Storage/Images/Employees/' + profile_picture" class="profile-img profile-img-xxl" *ngIf="profile_picture != null" (error)="profile_picture = null" (click)="previewImage($event, profile_picture)">
                <span class="fa-solid fa-user fa-xl fa-fw text-white" *ngIf="profile_picture == null"></span>
              </div>
              <div class="mt-2 mt-md-0 ms-0 ms-md-3">
                <h4 class="text-center text-md-start m-0" style="line-height: 1.1;">{{ auth.full_name }}</h4>
                <h6 class="text-center text-md-start m-0">{{ auth.job_name }}</h6>
              </div>
            </div>
            <hr>
            <div class="row align-items-center">
              <div class="col-md">
                <div class="text-center">
                  <h6 class="text-muted m-0">
                    <small>Today is</small>
                  </h6>
                  <h1 class="font-monospace lh-1 m-0" *ngIf="current_time != null">{{ current_time }}</h1>
                  <h6 class="lh-1 m-0" *ngIf="current_date != null">{{ current_date }}</h6>
                </div>
              </div>
              <div class="col-md-6" *ngIf="!(is_locked$ | async)">
                <div class="btn-group w-100 mt-4 mt-md-0">
                  <button type="button" class="btn btn-secondary" (click)="lockScreen()">
                    <ng-container>
                      <span class="fa-solid fa-lock fa-fw"></span>
                      <span class="ms-2">Lock Screen</span>
                    </ng-container>
                  </button>
                  <button type="button" class="btn btn-danger" (click)="logout()">
                    <span class="fa-solid fa-right-from-bracket fa-fw"></span>
                    <span class="ms-2">Sign Out</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow-lg flex-fill" *ngIf="is_locked$ | async">
          <div class="card-body">
            <div class="d-flex flex-column align-items-center justify-content-center h-100 w-100">
              <div class="w-100 w-md-60">
                <div class="display-subtitle mb-1">App is locked</div>
                <p>We'll always keep your attendance safe</p>
                <form [formGroup]="lock_form" (submit)="unlockScreen()">
                  <div class="form-group">
                    <label for="" class="mb-1">Password:</label>
                    <input type="password" class="form-control form-control-lg" formControlName="Password">
                  </div>
                  <div class="text-end">
                    <button type="submit" class="btn btn-secondary">
                      <span class="fa-solid fa-unlock fa-fw"></span>
                      <span class="ms-2">Unlock Screen</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow-lg flex-fill" *ngIf="!(is_locked$ | async)">
          <div class="card-header pb-0 pt-3 bg-transparent">
            <h5 class="d-flex justify-content-between m-0">
              <span>Current Shift</span>
              <button type="button" class="btn btn-success btn-sm py-1 px-2" (click)="getCurrentShift()">
                <span class="fa-solid fa-arrows-rotate fa-fw"></span>
                <span class="ms-2">Refresh</span>
              </button>
            </h5>
            <hr class="mb-0">
          </div>
          <div class="card-body">
            <div class="mb-3" *ngIf="ip_address != null">
              <h6 class="text-muted lh-1 m-0">
                <small>IP Address:</small>
              </h6>
              <h3 class="lh-1 m-0">{{ ip_address }}</h3>
            </div>
            <ng-container *ngIf="platform == 'mobile';">
              <p>We are very sorry but this module is not accessible on mobile devices.</p>
              <div class="alert alert-danger text-white">
                <h4 class="alert-heading">Warning:</h4>
                <p>If for some reason, this module glitch out and suddenly display the module on mobile devices, <strong>do not use it</strong>. Unauthorized use of this module is subject for interrogation.</p>
              </div>
            </ng-container>
            <ng-container *ngIf="platform == 'desktop';">
              <div class="timeline timeline-one-side">
                <div class="timeline-block mb-3">
                  <div class="timeline-step">
                    <span class="fa-solid fa-user-clock text-success fa-fw"></span>
                  </div>
                  <div class="timeline-content">
                    <h6 class="text-secondary mb-0">
                      <small>Time In</small>
                    </h6>
                    <ng-container *ngIf="shift != null; else alterClockInContent">
                      <h2 class="font-monospace lh-1 m-0">{{ shift.FormattedTimeIn.Time }}</h2>
                      <h6 class="lh-1 m-0">{{ shift.FormattedTimeIn.Date }}</h6>
                    </ng-container>
                    <ng-template #alterClockInContent>
                      <button class="btn btn-success" (click)="clockIn()">
                        <span class="fa-solid fa-play fa-fw"></span>
                        <span class="ms-2">Clock In</span>
                      </button>
                    </ng-template>
                  </div>
                </div>
                <ng-container *ngIf="shift != null">
                  <div class="timeline-block mb-3">
                    <div class="timeline-step">
                      <span class="fa-solid fa-pause text-primary fa-fw"></span>
                    </div>
                    <div class="timeline-content">
                      <h6 class="text-secondary mb-0">
                        <small>Break In</small>
                      </h6>
                      <ng-container *ngIf="!can_break_in; else alterBreakInContent">
                        <h2 class="font-monospace lh-1 m-0">{{ shift.FormattedBreakIn.Time }}</h2>
                        <h6 class="lh-1 m-0">{{ shift.FormattedBreakIn.Date }}</h6>
                      </ng-container>
                      <ng-template #alterBreakInContent>
                        <button class="btn btn-primary" (click)="breakIn()" [disabled]="is_processing">
                          <span class="fa-solid fa-play fa-fw"></span>
                          <span class="ms-2">Break In</span>
                        </button>
                      </ng-template>
                    </div>
                  </div>
                  <div class="timeline-block mb-3" *ngIf="shift.BreakIn != null">
                    <div class="timeline-step">
                      <span class="fa-solid fa-play text-primary fa-fw"></span>
                    </div>
                    <div class="timeline-content">
                      <h6 class="text-secondary mb-0">
                        <small>Break Out</small>
                      </h6>
                      <ng-container *ngIf="!can_break_out; else alterBreakOutContent">
                        <h2 class="font-monospace lh-1 m-0">{{ shift.FormattedBreakOut.Time }}</h2>
                        <h6 class="lh-1 m-0">{{ shift.FormattedBreakOut.Date }}</h6>
                      </ng-container>
                      <ng-template #alterBreakOutContent>
                        <button class="btn btn-primary" (click)="breakOut()" [disabled]="is_processing">
                          <span class="fa-solid fa-stop fa-fw"></span>
                          <span class="ms-2">Break Out</span>
                        </button>
                      </ng-template>
                    </div>
                  </div>
                  <div class="timeline-block mb-3">
                    <div class="timeline-step">
                      <span class="fa-solid fa-user-clock text-danger fa-fw"></span>
                    </div>
                    <div class="timeline-content">
                      <h6 class="text-secondary mb-0">
                        <small>Time Out</small>
                      </h6>
                      <ng-container *ngIf="shift.TimeOut != null">
                        <h2 class="font-monospace lh-1 m-0">{{ shift.FormattedTimeOut.Time }}</h2>
                        <h6 class="lh-1 m-0">{{ shift.FormattedTimeOut.Date }}</h6>
                      </ng-container>
                      <button class="btn btn-danger ms-auto" (click)="clockOut()" [disabled]="!can_clock_out" *ngIf="!is_processing">
                        <span class="fa-solid fa-stop fa-fw"></span>
                        <span class="ms-2">Clock Out</span>
                      </button>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="text-center p-3" *ngIf="is_processing">
                <span class="fa-solid fa-spinner fa-pulse fa-fw"></span>
                <span class="ms-2">Processing Data...</span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
