import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL, HTTP_OPTIONS } from './utility.service';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';

export const TOKEN_KEY = 'jwt-token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  helper = new JwtHelperService();

  private userSubject = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient
  ) {
    if(localStorage.getItem(TOKEN_KEY) != null) {
      let decodedToken = this.helper.decodeToken(localStorage.getItem(TOKEN_KEY));

      this.userSubject.next(decodedToken);
    }
  }

  login(params: any) {
    return this.http.post(`${API_URL}/auth/login`, params, HTTP_OPTIONS).pipe(
      map((res: any) => {
        this.saveTokenSession(res.Token);

        localStorage.setItem('is-password-changed', JSON.stringify(false));

        return res;
      })
    );
  }

  checkCredentials(params: any) {
    return this.http.post(`${API_URL}/auth/check-credentials`, params, HTTP_OPTIONS);
  }

  changePassword(params: any) {
    return this.http.put(`${API_URL}/auth/change-password`, params, HTTP_OPTIONS);
  }

  resetPassword(params: any) {
    return this.http.put(`${API_URL}/auth/reset-password`, params, HTTP_OPTIONS);
  }

  logout() {
    let last_logged_in = localStorage.getItem('last-logged-in') != null ? JSON.parse(localStorage.getItem('last-logged-in')) : null;

    localStorage.clear();

    if(last_logged_in != null) {
      localStorage.setItem('last-logged-in', JSON.stringify(last_logged_in));
    }

    this.userSubject.next(null);
  }

  saveTokenSession(token: string) {
    localStorage.setItem(TOKEN_KEY, token);

    let decodedToken = this.helper.decodeToken(token);

    this.userSubject.next(decodedToken);
  }

  getUser() {
    let info = this.userSubject.getValue();

    if(info != null) {
      if(info.middle_name != null && info.middle_name.length > 0) {
        info.full_name = `${info.first_name} ${info.middle_name.substring(0, 1)}. ${info.last_name}`;
      } else {
        info.full_name = `${info.first_name} ${info.last_name}`;
      }

      if(typeof info.teams_joined == 'string') {
        info.teams_joined = JSON.parse(info.teams_joined);
      }
    }

    return info;
  }

  getProfilePicture() {
    if(this.getUser() == null) {
      return null;
    }

    return this.http.get(`${API_URL}/auth/profile-picture/get?idNumber=${this.getUser().id_number}`, HTTP_OPTIONS);
  }

  getProfilePictureAsGuest(id_number: string) {
    return this.http.get(`${API_URL}/auth/profile-picture/get?idNumber=${id_number}`, HTTP_OPTIONS);
  }

  shouldChangePassword() {
    let info = this.userSubject.getValue();

    return info != null && info.should_change_password === 'true';
  }

  isAllowed(security_code: any) {
    let info = this.userSubject.getValue();
    let permissions = info != null && info.permissions != null ? JSON.parse(info.permissions) : null;

    return permissions != null && permissions.indexOf(security_code) >= 0;
  }

  isOfficerInChargeOf(team_id: any) {
    let teams = this.getUser().team_ids.OfficerInCharge;

    return teams.length > 0 && teams.includes(team_id)
      ? true
      : false;
  }

  isAssistantOfficerInChargeOf(team_id: any) {
    let teams = this.getUser().team_ids.AssistantOfficerInCharge;

    return teams.length > 0 && teams.includes(team_id)
      ? true
      : false;
  }

  isMemberOf(team_id: any) {
    let teams = this.getUser().team_ids.Member;

    return teams.length > 0 && teams.includes(team_id)
      ? true
      : false;
  }

  isLoggedIn() {
    let is_logged_in = false;

    if(localStorage.getItem(TOKEN_KEY) != null) {
      is_logged_in = true;
    }

    return is_logged_in;
  }
}
