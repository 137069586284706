import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { AttendanceService } from 'src/app/services/attendance.service';
import { AuthService } from 'src/app/services/auth.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-time-clock',
  templateUrl: './time-clock.component.html',
  styleUrls: ['./time-clock.component.scss']
})
export class TimeClockComponent implements OnInit {
  website_version$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  api_version$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  last_logged_in: any = null;
  profile_picture: any = null;

  auth: any = null;

  interval: any = null;
  shift: any = null;
  current_stamp: any = null;
  current_date: any = null;
  current_time: any = null;
  flag: boolean = false;
  is_processing: boolean = false;
  ip_address: string = null;

  is_locked$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  login_form: FormGroup = new FormGroup({
    Username: new FormControl('', Validators.required),
    Password: new FormControl('', Validators.required),
    StaySignedIn: new FormControl(false)
  });

  lock_form: FormGroup = new FormGroup({
    Username: new FormControl('', Validators.required),
    Password: new FormControl('', Validators.required)
  });

  constructor(
    public utilityService: UtilityService,
    public authService: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private attendanceService: AttendanceService
  ) {
    this.website_version$.next(`v${this.utilityService.websiteVersion()}`);

    this.utilityService.getApiVersion().subscribe((result: any) => {
      this.api_version$.next(`v${result}`);
    });

    this.utilityService.getIpAddress().subscribe((result: any) => {
      this.ip_address = result.ip;
    }, (error: any) => {
      this.ip_address = null;
    });
  }

  ngOnInit(): void {
    this.auth = this.authService.getUser();

    if(localStorage.getItem('is-locked') != null) {
      this.is_locked$.next(JSON.parse(localStorage.getItem('is-locked')));
    }

    if(this.auth != null) {
      this.getCurrentShift();
    }

    this.currentDateAndTime();

    this.interval = setInterval(() => {
      this.currentDateAndTime();
    }, 1000);

    this.last_logged_in = this.utilityService.getSession('last-logged-in') != null ? JSON.parse(this.utilityService.getSession('last-logged-in')) : null;

    if(this.last_logged_in != null) {
      this.login_form.patchValue({
        Username: this.last_logged_in.id_number
      });

      this.authService.getProfilePictureAsGuest(this.last_logged_in.id_number).subscribe((result: any) => {
        this.profile_picture = result;
      });
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  switchAccount(event: any) {
    event.preventDefault();

    this.utilityService.setSession('last-logged-in', JSON.stringify(null));

    this.last_logged_in = this.utilityService.getSession('last-logged-in') != null ? JSON.parse(this.utilityService.getSession('last-logged-in')) : null;

    this.login_form.patchValue({
      Username: ''
    });
  }

  searchEmployee(id_number: any, callback: Function = null) {
    this.utilityService.searchEmployee(id_number).subscribe((result: any) => {
      if(callback != null) {
        callback(result);
      }
    });
  }

  login() {
    if(this.login_form.invalid) {
      this.utilityService.toast({
        icon: 'warning',
        title: 'Please fill up all required fields.'
      });

      return;
    }

    this.spinner.show();

    this.authService.login(this.login_form.getRawValue()).subscribe((result: any) => {
      this.spinner.hide();

      this.utilityService.toast({
        icon: 'success',
        title: 'Login successful.'
      });

      this.searchEmployee(this.login_form.get('Username').value, (result: any) => {
        this.utilityService.setSession('last-logged-in', JSON.stringify({
          id_number: result.Idnumber,
          first_name: result.FirstName
        }));
      });

      this.auth = this.authService.getUser();

      if(this.auth != null) {
        this.login_form.reset();

        this.getCurrentShift(null, () => {
          this.clockIn();
        });
      }
    }, (err: any) => {
      this.spinner.hide();

      this.utilityService.toast({
        icon: 'error',
        title: err.error
      });

      this.login_form.reset();
    });
  }

  logout() {
    this.utilityService.prompt({
      icon: 'warning',
      title: `You're about to sign out`,
      text: 'Are you sure you want to sign out now?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result: any) => {
      if(result.isConfirmed) {
        this.is_locked$.next(false);

        this.authService.logout();

        this.router.navigate(['/']);
      }
    });
  }

  lockScreen() {
    let flag = true;

    this.is_locked$.next(flag);

    localStorage.setItem('is-locked', JSON.stringify(flag));
  }

  unlockScreen() {
    let flag = false;

    this.is_locked$.next(flag);

    localStorage.setItem('is-locked', JSON.stringify(flag));
  }

  currentDateAndTime() {
    this.current_stamp = this.utilityService.nowInPh().format('YYYY-MM-DD HH:mm:ss');
    this.current_date = this.utilityService.parseStamp(this.current_stamp).format('MMMM D, YYYY');
    this.current_time = this.utilityService.parseStamp(this.current_stamp).format(`hh${this.flag ? ':' : ' '}mm A`);

    this.flag = !this.flag;
  }

  getCurrentShift(successCallback: Function = null, errorCallback: Function = null) {
    this.spinner.show();

    this.attendanceService.getCurrentShift(this.auth.id_number).subscribe((result: any) => {
      this.spinner.hide();

      this.shift = result;

      this.shift.FormattedTimeIn = this.shift.TimeIn != null ? this.formatShift(this.shift.TimeIn) : null;
      this.shift.FormattedTimeOut = this.shift.TimeOut != null ? this.formatShift(this.shift.TimeOut) : null;
      this.shift.FormattedBreakIn = this.shift.BreakIn != null ? this.formatShift(this.shift.BreakIn) : null;
      this.shift.FormattedBreakOut = this.shift.BreakOut != null ? this.formatShift(this.shift.BreakOut) : null;

      if(successCallback != null) {
        successCallback();
      }
    }, (error) => {
      this.spinner.hide();

      if(errorCallback != null) {
        errorCallback();
      }
    });
  }

  formatShift(stamp: any) {
    return {
      Date: this.utilityService.parseStamp(stamp).format('MMMM D, YYYY'),
      Time: this.utilityService.parseStamp(stamp).format('hh:mm A')
    };
  }

  breakIn() {
    this.shift.BreakIn = this.current_stamp;
    this.shift.FormattedBreakIn = this.formatShift(this.shift.BreakIn);

    this.updateShift();
  }

  breakOut() {
    this.shift.BreakOut = this.current_stamp;
    this.shift.FormattedBreakOut = this.formatShift(this.shift.BreakOut);

    this.updateShift();
  }

  clockIn() {
    this.shift = {
      RecordingIn: 'tcs',
      RecordingOut: null,
      IpAddressIn: this.ip_address,
      IpAddressOut: null,
      PlatformIn: this.platform,
      PlatformOut: null,
      UserAgentIn: navigator.userAgent,
      UserAgentOut: null,
      Idnumber: this.auth.id_number,
      TimeIn: null,
      TimeOut: null,
      BreakIn: null,
      BreakOut: null,
      FormattedTimeIn: null,
      FormattedTimeOut: null,
      FormattedBreakIn: null,
      FormattedBreakOut: null
    };

    this.shift.TimeIn = this.current_stamp;
    this.shift.FormattedTimeIn = this.formatShift(this.shift.TimeIn);

    this.updateShift();
  }

  clockOut() {
    this.shift.RecordingOut = 'tcs';
    this.shift.IpAddressOut = this.ip_address;
    this.shift.PlatformOut = this.platform;
    this.shift.UserAgentOut = navigator.userAgent;
    this.shift.TimeOut = this.current_stamp;
    this.shift.FormattedTimeOut = this.formatShift(this.shift.TimeOut);

    this.is_processing = true;

    setTimeout(() => {
      this.updateShift(() => {
        this.is_processing = false;
        this.shift = null;

        this.authService.logout();

        this.auth = null;

        this.router.navigate(['/']);
      });
    }, 2000);
  }

  updateShift(callback: Function = null) {
    this.spinner.show();

    this.attendanceService.recordShift(this.shift).subscribe((result: any) => {
      this.spinner.hide();

      this.utilityService.toast({
        icon: 'success',
        title: 'Shift Details has been updated.'
      });

      if(callback != null) {
        callback();
      }
    }, (error) => {
      this.spinner.hide();

      this.utilityService.toast({
        icon: 'error',
        title: error.error
      });
    });
  }

  get platform() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)
      || this.has_touch_support
    ) {
      return 'mobile';
    }

    return 'desktop';
  }

  get has_touch_support() {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }

  get can_break_in() {
    return this.shift.TimeIn != null
      && this.shift.BreakIn == null;
  }

  get can_break_out() {
    return this.shift.TimeIn != null
      && this.shift.BreakIn != null
      && this.shift.BreakOut == null;
  }

  get can_clock_out() {
    return this.shift.TimeIn != null
      && (this.shift.BreakIn == null
        || (this.shift.BreakIn != null && this.shift.BreakOut != null)
      );
  }
}
